const brandColors = {
    primary: '#A6B54D',
    primarySelect: '#88943F',
    disabled: '#D6DBB0',
    secondary: '#BDCA70',
    secondarySelect: '#9CAB49',
    tertiary: '#F1F6CF',
    welcomeCircles: '#0B719D',
};

export default {
    colors: {
        ...brandColors,
    },
};
