const brandColors = {
    primary: '#FF612B',
    primarySelect: '#BA6A19',
    disabled: '#E0C3A6',
    secondary: '#F1B374',
    secondarySelect: '#CC9863',
    tertiary: '#FFF1E3',
    welcomeCircles: '#0B719D',
};

export default {
    colors: {
        ...brandColors,
    },
};
