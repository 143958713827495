const brandColors = {
    primary: '#D81F15',
    primarySelect: '#B01911',
    disabled: '#E0A9A6',
    secondary: '#E87F79',
    secondarySelect: '#C26A65',
    tertiary: '#FFE4E3',
    welcomeCircles: '#0B719D',
};

export default {
    colors: {
        ...brandColors,
    },
};
