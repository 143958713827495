const brandColors = {
    primary: '#FF612B',
    primarySelect: '#CF6A1E',
    disabled: '#EABE9D',
    secondary: '#F2A66D',
    secondarySelect: '#CF8E5D',
    tertiary: '#FFEFE3',
    welcomeCircles: '#0B719D',
};

export default {
    colors: {
        ...brandColors,
    },
};
